import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Parametric vector design",
  "hideInMenu": true,
  "path": "root"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`CAS Arts & Design in Practice · Signalwerk GmbH – Stefan Huber · 2017`}</p>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui/"
        }}>{`User Interface`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/research/"
        }}>{`Research`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/todo/"
        }}>{`ToDo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      